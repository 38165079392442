/*!
 * main.scss
 * Author - Brendan Smith 
 */

// Imports overrides and regular bootstrap
// Omits the need for regular bootstrap.scss
//
@import "./stgeorgetheme";
// Bootstrap next!

@import "../../../node_modules/bootstrap/scss/bootstrap";


// imports particular theme tweaks
@import "./stgeorgethemeextras";

